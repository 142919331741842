import React from "react";
import "./Footer.css"; // Import the CSS file for styling

export default function Footer() {
  return (
    <div className="Footer">
      <div className="bottom">
        <img src="logo.png" alt="Company Logo" />
        <div className="contact">
          <div>
            <strong>Address:</strong>
            <span>Addis Ababa</span>
          </div>
          <div>
            <strong>Email:</strong>
            <span>
              <a href="mailto:Kirubel@focilab.com">Kirubel@focilab.com</a>
            </span>
          </div>
          <div>
            <strong>Phone number:</strong>
            <span>
              <a href="tel:+251960551172">0960551172</a>
            </span>
          </div>
        </div>
        <div className="socials">
          <div>
            <a href="https://www.facebook.com/profile.php?id=61561093664977">
              Facebook
            </a>
          </div>

          <a href="https://t.me/The_Kirubel" target="_blank" rel="noreferrer">
            Telegram
          </a>
        </div>
      </div>
      <p>&copy; 2024 FociLab. All rights reserved.</p>
    </div>
  );
}
