import React from "react";
import "./Serevices.css";
export default function Swervices() {
  return (
    <div className="services">
      <h2 className="header">Elevate Your Hotel Services with AI</h2>
      <p className="des">
        Boost bookings and enhance guest experiences with our AI chatbot,
        designed specifically for hotels, providing personalized assistance and
        seamless integration on your website.
      </p>
      <ul>
        <li>
          <img src="img/bubble-chat.png" alt="chat" />
          <h2>AI Agent</h2>
          <p>
            Boost booking upto 20% and Enhance guest experiences with our AI
            chatbot integrated into your hotel's website. It assists visitors
            with inquiries, booking processes, and provides personalized
            recommendations to streamline their journey.
          </p>
          <div>
            <button>Personalize guest interactions</button>
          </div>
        </li>

        <li>
          <img src="img/automation.png" alt="automation" />
          <h2>Advanced AI Integration</h2>
          <p>
            Extend the capabilities of our AI technology to integrate with hotel
            operations. Guests who have already booked can interact seamlessly
            with hotel staff, from placing room service orders to asking
            internal questions about facilities and services.
          </p>
          <div>
            <button>Elevate guest experiences</button>
          </div>
        </li>
        <li>
          <img src="img/robot.png" alt="robot" />
          <h2>AI Automation</h2>
          <p>
            Implement advanced AI systems that automate internal hotel tasks
            such as email classification, data analytics, and operational
            workflows. This enables your team to focus more on guest
            satisfaction and strategic initiatives.
          </p>
          <div>
            <button>Optimize operational efficiency</button>
          </div>
        </li>
      </ul>
    </div>
  );
}
