import React from "react";
import "./Home.css";
import Hero from "../components/Hero";
import Nav from "../components/Nav";
import Serevices from "../components/Serevices";
import Process from "../components/Process";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import Benefits from "../components/Benefits";
export default function Home() {
  return (
    <div>
      <Nav />
      <Hero />
      <Serevices />
      <Process />
      <Benefits />
      <Contact />
      <Footer />
    </div>
  );
}
