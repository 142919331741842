import React, { useState, useRef } from "react";
import "./Contact.css";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const nameInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send the form data to the server
      await fetch("https://new-tweet-box-api.vercel.app/submit-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // Set the submitted state to true to display the thank you message
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleDemoButtonClick = () => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  };

  return (
    <div className="contact" id="contact">
      <h2 className="next">Next steps</h2>

      <div className="contact-container">
        <div className="info-section">
          <div className="badge-container">
            <span className="badge blue">1 MONTH BUILD TIME</span>
            <span className="badge gray">LIMITED AVAILABILITY</span>
          </div>
          <h2>What's included?</h2>
          <p>
            Work with FociLab on one time fee and (exclusive pricing) management
            package.
          </p>
          <hr />
          <h3>Includes:</h3>
          <ul className="includes-list">
            <li>
              <img src="Frame.svg" alt="-" /> Initial discovery workshop
            </li>
            <li>
              <img src="Frame.svg" alt="-" /> CRM Integration{" "}
            </li>
            <li>
              <img src="Frame.svg" alt="-" /> Complete system setup and build
            </li>
            <li>
              <img src="Frame.svg" alt="-" /> Deliverable/solution roadmap
            </li>
            <li>
              <img src="Frame.svg" alt="-" /> Full time developer, build team
            </li>
            <li>
              <img src="Frame.svg" alt="-" /> Automated email & SMS
            </li>
            <li>
              <img src="Frame.svg" alt="-" /> Complete setup and integration
            </li>
          </ul>
          <button className="demo-button" onClick={handleDemoButtonClick}>
            Book a Demo Call
          </button>
        </div>

        <div className="form-section">
          {submitted ? (
            <div className="thank-you-message">
              <h2>Thank you for submitting your information!</h2>
              <p>
                We will get in touch with you soon. <br /> If you need immediate
                assistance, please call us at: <br />{" "}
                <strong>0990551172</strong>
              </p>
            </div>
          ) : (
            <div>
              <h2>BOOK YOUR CALL TODAY</h2>
              <p>
                Leave your card at home. This is purely a discovery call, not a
                sales call. We need to make sure we can help you before we
                proceed with anything.
              </p>
              <form onSubmit={handleSubmit}>
                <label>
                  Name *
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    ref={nameInputRef}
                    required
                  />
                </label>

                <label>
                  Email *
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Phone *
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Company Name *
                  <input
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="checkbox-container">
                  <input type="checkbox" name="agreement" required />
                  <span>
                    I agree to <a href="#terms">terms & conditions</a> provided
                    by the company. By providing my phone number, I agree to
                    receive text messages from the business.
                  </span>
                </label>
                <button type="submit">Submit</button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
