import React from "react";
import "./Process.css"; // Import the CSS file for styling

const Process = () => {
  return (
    <div id="how-it-works" className="presence-section background-bg">
      <div className="presence-container">
        <div className="presence-content">
          <div className="overflow-hidden">
            <h3 className="presence-title heading3">How it works!</h3>
            <div className="story-content-text body-03">
              Say goodbye to the lengthy onboarding processes associated with
              agencies. Simply book in your demo call and we'll walk you through
              our simple process.
            </div>
            <a
              href="#pricing"
              className="price-btn button-02 white-bg black-text blue middle"
            >
              Start working with us today
            </a>
          </div>

          <div className="presence-list">
            <div className="presence-item">
              <div className="presence-bullet yellow-bg off-yellow-text">1</div>
              <h4 className="presence-subtitle subheading2">
                <strong>Demo Call</strong>
              </h4>
              <p className="presence-text body-02">
                Book in your demo call, we'll find out if what we do is a good
                fit for your business, we'll also walk you through our pricing
                model and find out more about your current setup.
              </p>
            </div>
            <div className="presence-item">
              <div className="presence-bullet purple-bg off-purple-text">2</div>
              <h4 className="presence-subtitle subheading2">Onboarding</h4>
              <p className="presence-text body-02">
                Once we know we can help you and you're happy to proceed you'll
                be onboarded into our Slack channel where you'll meet the team.
                This is where we provide updates to you ongoing.
              </p>
            </div>
            <div className="presence-item">
              <div className="presence-bullet red-bg off-red-text">3</div>
              <h4 className="presence-subtitle subheading2">Build timeline</h4>
              <p className="presence-text body-02">
                Based on our discovery call together we'll build a build
                timeline around the current setup you have and our systems
                ensuring we can seamlessly integrate into your business.
              </p>
            </div>
            <div className="presence-item">
              <div className="presence-bullet green-bg off-green-text">4</div>
              <h4 className="presence-subtitle subheading2">
                Build & integration
              </h4>
              <p className="presence-text body-02">
                Once approved, the team will get to work, updating you every
                step of the way and working alongside you inside our Slack
                channel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
