import React from "react";
import "./Benefits.css";

export default function Benefits() {
  const benefits = [
    {
      title: "Increased Booking Rates",
      description:
        "Boost booking rates by up to 20% with instant, personalized responses.",
      icon: "bar-chart.png",
    },
    {
      title: "Faster Response Time",
      description: "Achieve 90% faster response times with 24/7 availability.",
      icon: "fast-time.png",
    },
    {
      title: "Enhanced Guest Experience",
      description:
        "Provide personalized assistance, increasing guest satisfaction.",
      icon: "costumer.png",
    },
    {
      title: "Operational Efficiency",
      description:
        "Automate routine tasks, freeing up staff for higher-priority activities.",
      icon: "robotic.png",
    },
    {
      title: "Cost Savings",
      description:
        "Reduce operational costs by handling customer interactions automatically.",
      icon: "discount.png",
    },
    {
      title: "Data-Driven Personalization",
      description: "Collect and analyze guest data to offer tailored services.",
      icon: "bot.png",
    },
  ];

  return (
    <div className="benefits-container">
      <h2>Benefits of our AI Agents</h2>
      <div className="benefits-grid">
        {benefits.map((benefit, index) => (
          <div className="benefit-item" key={index}>
            <img src={"icons/" + benefit.icon} alt="icon" />
            <h3>{benefit.title}</h3>
            <p>{benefit.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
