import React from "react";
import "./Voiceflow.css";

export default function Voiceflow() {
  return (
    <div className="voiceflow">
      <nav>
        <div className="nav-container">
          <img src="voiceflowC.png" alt="Logo" className="logo" />{" "}
          {/* Replace with actual logo */}
          <div className="nav-links">
            <ul>
              <li>
                <a href="#price-vf">Price</a>
              </li>
              <li>
                <a href="#about-vf">About Me</a>
              </li>
              <li>
                <a href="https://voiceflow-customizer.vercel.app/">
                  Try For Free
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="hero-vf">
        <h1>The Final Touch for Your Chatbots</h1>
        <p>Double your open rate by using animations and custom messages</p>
        <div className="message">
          <div>
            <a href="https://voiceflow-customizer.vercel.app/">
              <button>Get Started</button>
            </a>
            <br />
            <span>No Email Required :)</span>
          </div>
          <img src="/voice/group.png" alt="chats" />
        </div>
      </div>
      <div className="features-vf">
        <div className="left">
          <img src="/voice/laptop.png" alt="feature" />
        </div>
        <div className="right">
          <h2>Features</h2>
          <ul>
            <li>Add Custome messages to grap visitors attension</li>
            <li>Add animations to make your chatbots more appealing</li>
            <li>Change color of the chabot icon</li>
            <li>Change the size of the chabot icon</li>
          </ul>
        </div>
      </div>
      <div className="price-vf" id="price-vf">
        <h2>Price</h2>
        <p className="d">
          Double your open rate by using our animations and custom messages. and
          support us by purchasing our Golden Token.
        </p>
        <div className="package">
          <div className="ten">
            <h3>$10 for 5 Exports</h3>
            <p>
              Purchase a $10 token to unlock 5 exports, which equates to just $2
              per export. Perfect for those who need occasional access.
            </p>
            <a href="https://voiceflow-customizer.vercel.app/">
              <button>Get Started</button>
            </a>
          </div>

          <div className="fifty">
            <h3>$50 One-Time Payment</h3>
            <p>
              Secure your Golden Token with a one-time payment of $50. Enjoy
              unlimited access to the tool and become an exclusive early
              adopter. This means you'll receive all upcoming features and
              updates.
            </p>
            <a href="https://voiceflow-customizer.vercel.app/">
              <button>Get Started</button>
            </a>
          </div>
        </div>
      </div>
      <div className="voice-about" id="about-vf">
        <div className="img">
          <img src="/voice/me.jfif" alt="" />
          <p>AI agecy owner | Programmer | Writer</p>
        </div>
        <div className="me">
          <h2>About Me.</h2>
          <p>
            I am Kirubel, a computer science student from Ethiopia, and I also
            run a hospitality-focused AI agency. I created this project for
            myself, and I believe it will also benefit other agency owners. I am
            working to make it a free tool, but in the meantime, please show
            your support by purchasing our token.{" "}
          </p>
          <div className="contacts">
            <h3>Contact Me</h3>
            <ul>
              <li>
                <strong>Email:</strong>{" "}
                <a href="mailto:kiztopia.2020@gmail.com">
                  kiztopia.2020@gmail.com
                </a>
              </li>
              <li>
                <strong>Telegram:</strong>{" "}
                <a
                  href="https://t.me/The_Kirubel"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The_Kirubel
                </a>
              </li>
              <li>
                <strong>Skool:</strong>{" "}
                <a
                  href="https://www.skool.com/@kirubel-demelash-7967?g=ai-automation-agency-hub-8466"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AI Automation Agency Hub
                </a>
              </li>
              <li>
                <strong>X (Twitter):</strong>{" "}
                <a
                  href="https://x.com/TheKirubel"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TheKirubel
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-vf">
        2024 All rights are recived. This tools is not affiliated with
        Voiceflow.
      </div>
    </div>
  );
}
