import React, { useState, useEffect } from "react";
import "./Hero.css";

export default function Hero() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = ["img/hero.png", "img/hero1.png", "img/hero2.png"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleBookClick = () => {
    const contactElement = document.getElementById("contact");
    if (contactElement) {
      contactElement.scrollIntoView({ behavior: "smooth" });
      const nameInput = contactElement.querySelector("input[name='name']");
      if (nameInput) {
        setTimeout(() => {
          nameInput.focus();
        }, 500); // Delay to ensure the form is in view before focusing
      }
    }
  };

  return (
    <div className="hero">
      <div className="content">
        <h1>
          Elevate Customer Satisfaction with the Power of{" "}
          <span>AI Agents.</span>
        </h1>
        <p>
          Enhance guest experiences and streamline bookings with our AI agents,
          providing instant, personalized service 24/7
        </p>
        <a href="#!" onClick={handleBookClick}>
          Book A Free Discovery Call Now!
        </a>
      </div>
      <div className="image">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt=""
            className={index === currentImageIndex ? "visible" : "hidden"}
          />
        ))}
      </div>
    </div>
  );
}
