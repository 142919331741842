import React from "react";
import "./Nav.css";
export default function Nav() {
  return (
    <div className="nav">
      <div className="logo">
        <img src="logo.png" alt="" />
      </div>
    </div>
  );
}
